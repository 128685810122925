import { PLAYER_REGEX } from "~/utils/constants";

export const state = () => ({
	superAdmin: [],
	currency: [],
	languages: [],
	translationString: {},
	loadingStringsForTranslation: false,
	agents: [],
	companyBankList: [],
	companyBankAccounts: [],
	playerBankList: [],
	playerBankForDeposit: [],
	admins: [],
	playerIds: [],
	allBanners: [],
	allBannersPerformance: [],
	gameTypes: [],
	thirdPartyPaymentGateways: [],
	thirdPartyPaymentGatewaysChannels: [],
	exchangeRates: [],
	companyCryptoWallets: [],
	playerCryptoWallets: [],
	depositFlow: {},
	domains: {},
	paymentTypes: [],
	companyThirdPartyChannels: [],
	loading: false,
	rsaPublicKeys: [],
	searchGames: [],
	operatorInfo: {},
	gameProviders: [],
	operationSetting: {},
	thirdPartyPaymentChannelCurrency: [],
	thirdPartyPaymentChannelsTypes: [],
	bannerCategories: [],
	cryptoCurrencies: [],
	companyCryptoWalletForManualDeposit: {
		company_crypto_wallets: [],
		deposit_info: {},
		exchange_rate: {}
	},
	companyCryptoWalletForManualDepositLoading: false,
	companyCryptoWalletForWithdrawal: [],


	// cancel token
	masterCancelToken: {}
})

export const actions = {
	async superAdmins({ commit }) {
		await this.$axios.get('/master/verified-finances-tfa-users')
			.then((data) => {
				commit('setSuperAdmin', data.data)
				return true;
			})
			.catch(() => false)
	},
	async currency({ commit }, params) {
		await this.$axios.get('/master/currency', { params })
			.then((data) => {
				commit('setCurrency', data.data)
				return true;
			})
			.catch(() => false)
	},
	async languages({ commit, state, dispatch }, params) {
		commit('setMasterCancelToken', { languages: this.$axios.CancelToken.source() })

		const customParams = {}
		if (params?.is_active !== false) {
			customParams.is_active_bo = true
		}
		await this.$axios
			.get(`master.languages`,
				{
					params: {
						...customParams
					},
					cancelToken: state.masterCancelToken.languages.token
				}
			)
			.then((response) => {
				const currentLocale = this.$cookie.get('locale')

				// Check if the Login-User's language is active
				if (response.data?.length) {
					const check = response.data.find(el => (el.code).toLowerCase() === currentLocale?.toLowerCase())
					if (!check) {
						const setNewDefaultLocale = response.data[0]?.code
						this.$cookie.set('locale', setNewDefaultLocale)
						dispatch('settings/locale', setNewDefaultLocale, { root: true })
					}
				}
				commit('setLanguages', response.data)
			})
			.catch(() => false)
	},
	async translationStrings({ commit, dispatch }, language = 'th-TH') {
		try {
			// This logic is to make the flag and language are always the same
			this.$cookie.set('locale', language)
			dispatch('settings/locale', language, { root: true })
			// read and understand on README.md file `SERVER_URL`
			let path = `/language-strings/language/${language}`
			if (!this.$config.DEVELOPMENT && process.server) {
				path = this.$config.SERVER_URL + path
			}
			await this.$axios.get(path, {
				params: {
					used_in: '1,2'
				}
			})
				.then((response) => {
					const convertArrayToObject = Object.fromEntries(response.data.map(item => [item.code, item.string]));
					commit('setTranslationStrings', convertArrayToObject)

				})
				.catch(() => {
					return false
				})
		} catch (error) {
			return false
		}
	},
	async agents({ commit, state }, params = {}) {
		if (state.agents?.length > 0) {
			commit('setAgents', state.agents)
			return;
		}
		commit('setMasterCancelToken', { agents: this.$axios.CancelToken.source() })
		params.currency = 'THB' // Don't worry about this, THB will not affect anything because axios will remove it automatically
		await this.$axios.get('/master/agents', {
			params,
			cancelToken: state.masterCancelToken.agents.token
		})
			.then((data) => {
				commit('setAgents', data.data)
				return true;
			})
			.catch((_) => false)
	},
	async companyBankList({ commit, state }, params) {
		// if (state.companyBankList?.length > 0) {
		// 	commit('setCompanyBankList', state.companyBankList)
		// 	return;
		// }
		commit('setMasterCancelToken', { companyBankList: this.$axios.CancelToken.source() })
		await this.$axios.get('/master/company-bank-list', {
			params,
			cancelToken: state.masterCancelToken.companyBankList.token
		})
			.then((data) => {
				commit('setCompanyBankList', data.data)
				return true;
			})
			.catch((_) => false)
	},
	async playerBankList({ commit, state }, params) {
		const id = params.player_id;
		delete params.player_id;
		commit('setMasterCancelToken', { playerBankList: this.$axios.CancelToken.source() })
		await this.$axios.get(`/master/player-bank-account/${id}`, {
			params,
			cancelToken: state.masterCancelToken.playerBankList.token
		})
			.then((data) => {
				commit('playerBankList', data.data)
				return true;
			})
			.catch((_) => false)
	},
	async playerBanksForDeposit({ commit, state }, params) {
		const id = params.player_id;
		delete params.player_id;
		await this.$axios.get(`/master/player-bank-account/${id}`, {
			params
		})
			.then((data) => {
				commit('playerBankForDeposit', data.data)
				return true;
			})
			.catch((_) => false)
	},
	async companyBankAccounts({ commit, state }, params) {
		commit('setMasterCancelToken', { companyBankAccounts: this.$axios.CancelToken.source() })
		commit('setLoading', true)
		await this.$axios.get('/master/company-bank-accounts', {
			params,
			cancelToken: state.masterCancelToken.companyBankAccounts.token
		})
			.then((data) => {
				commit('setCompanyBankAccounts', data.data)
				return true;
			})
			.catch((_) => false)
		commit('setLoading', false)
	},
	async searchAdmin({ commit, state }, params) {
		if (state.masterCancelToken.searchAdmin) {
			state.masterCancelToken.searchAdmin?.cancel('')
		}
		commit('setMasterCancelToken', { searchAdmin: this.$axios.CancelToken.source() })
		if (params.name?.length <= 3) return;

		await this.$axios.get('/master/users', { params, cancelToken: state.masterCancelToken.searchAdmin.token })
			.then((data) => {
				commit('setAdmins', data.data)
				return true;
			})
			.catch((_) => false)
	},
	async searchPlayerId({ commit, state }, data) {
		const params = JSON.parse(JSON.stringify(data))
		if (params?.player_id) {
			params.player_id = params?.player_id?.toLowerCase()
			const isMatch = PLAYER_REGEX
			if (!isMatch.test(params?.player_id)) {
				return null;
			}
		}

		if (state.masterCancelToken.searchingPlayerId) {
			state.masterCancelToken.searchingPlayerId.cancel('')
		}
		commit('setMasterCancelToken', { searchingPlayerId: this.$axios.CancelToken.source() })


		await this.$axios.get('/master/player-parameters',
			{
				params,
				cancelToken: state.masterCancelToken.searchingPlayerId.token
			}
		)
			.then((data) => {
				commit('setPlayerIds', data.data)
				return true;
			})
			.catch((_) => false)
	},
	async allBanners({ commit, state }, params) {
		if (!params) return;
		if (state.masterCancelToken.searchAllBanner) {
			state.masterCancelToken.searchAllBanner.cancel('')
		}
		commit('setMasterCancelToken', { searchAllBanner: this.$axios.CancelToken.source() })
		await this.$axios.get('/master/banners', { params, cancelToken: state.masterCancelToken.searchAllBanner.token })
			.then((data) => {
				commit('allBanners', data.data)
				return true;
			})
			.catch((_) => false)
	},
	async allMarketingPerformanceBanners({ commit, state }, params) {
		if (!params) return;
		if (state.masterCancelToken.searchAllBannerForPerformance) {
			state.masterCancelToken.searchAllBannerForPerformance.cancel('')
		}
		commit('setMasterCancelToken', { searchAllBannerForPerformance: this.$axios.CancelToken.source() })
		await this.$axios.get('/master/marketing-tools/banners/search', { params, cancelToken: state.masterCancelToken.searchAllBannerForPerformance.token })
			.then((data) => {
				commit('allBannersPerformance', data.data)
				return true;
			})
			.catch((_) => false)
	},
	async gameTypes({ commit, state }, params) {
		if (state.gameTypes?.length > 0) {
			// commit('setGameTypes', state.gameTypes)
			return;
		}
		commit('setMasterCancelToken', { gameTypes: this.$axios.CancelToken.source() })
		await this.$axios.get('/master/game-types', {
			params,
			cancelToken: state.masterCancelToken.gameTypes.token
		})
			.then((data) => {
				commit('gameTypes', data.data)
				return true;
			})
			.catch((_) => false)
	},
	async thirdPartyPaymentGateways({ commit, state }, currency) {
		commit('setMasterCancelToken', { thirdPartyPaymentGateways: this.$axios.CancelToken.source() })
		await this.$axios.get(`/master/company-third-party-providers/${currency}`, { cancelToken: state.masterCancelToken.thirdPartyPaymentGateways.token })
			.then((data) => {
				commit('setThirdPartyPaymentGateways', data.data)
				return true;
			})
			.catch((_) => false)
	},
	async thirdPartyPaymentGatewaysChannel({ commit, state }, params) {
		commit('setMasterCancelToken', { thirdPartyPaymentGatewaysChannel: this.$axios.CancelToken.source() })
		await this.$axios.get('/master/third-party-payment-channels', { params, cancelToken: state.masterCancelToken.thirdPartyPaymentGatewaysChannel.token })
			.then((data) => {
				commit('setThirdPartyPaymentGatewaysChannel', data.data)
				return true;
			})
			.catch((_) => false)
	},
	async exchangeRates({ commit, state }, params) {
		commit('setMasterCancelToken', { exchangeRate: this.$axios.CancelToken.source() })
		await this.$axios.get(`/master/exchange-rate`, { cancelToken: state.masterCancelToken.exchangeRate.token })
			.then((data) => {
				commit('setExchangeRates', data.data)
				return true;
			})
			.catch((_) => false)
	},
	async companyCryptoWallets({ commit, state }, params) {
		commit('setMasterCancelToken', { companyCryptoWallets: this.$axios.CancelToken.source() })
		await this.$axios.get(`/master/company-crypto-wallet/wallet-list`, {
			params,
			cancelToken: state.masterCancelToken.companyCryptoWallets.token
		})
			.then((data) => {
				commit('setCompanyCryptoWallets', data.data)
				return true;
			})
			.catch((_) => false)
	},
	async playerCryptoWallets({ commit, state }, params) {
		if (state.masterCancelToken.playerCryptoWallets) {
			state.masterCancelToken.playerCryptoWallets?.cancel('')
		}
		commit('setMasterCancelToken', { playerCryptoWallets: this.$axios.CancelToken.source() })
		const response = await this.$axios.get(`/master/player-crypto-wallet/${params}`, {
			cancelToken: state.masterCancelToken.playerCryptoWallets.token
		})
			.then((data) => {
				commit('setPlayerCryptoWallets', data.data)
				return data.data;
			})
			.catch((_) => false)
		return response;
	},
	async depositFlows({ commit, state }, params) {
		commit('setMasterCancelToken', { depositFlows: this.$axios.CancelToken.source() })
		await this.$axios.get(`/master/deposit-flow`, {
			cancelToken: state.masterCancelToken.depositFlows.token
		})
			.then((data) => {
				commit('setDepositFlow', data.data)
				return true;
			})
			.catch((_) => false)
	},
	async getDomainNs({ commit }, domain) {
		const response = await this.$axios.get(`/master/get-domain-ns/${domain}`)
			.then((data) => {
				commit('setDomains', data.data)
				return true;
			})
			.catch((_) => false)
		return response
	},
	async paymentTypes({ commit }, params) {
		const response = await this.$axios.get(`/master/payment-types`, { params })
			.then((data) => {
				commit('paymentTypes', data.data)
				return true;
			})
			.catch((_) => false)
		return response
	},
	async companyThirdPartyChannels({ commit }, params) {
		commit('setMasterCancelToken', { companyThirdPartyChannels: this.$axios.CancelToken.source() })
		const response = await this.$axios.get(`/master/company-third-party-payment-channels`, { params })
			.then((data) => {
				commit('companyThirdPartyChannels', data.data)
				return true;
			})
			.catch((_) => false)
		return response
	},
	async gameProviders({ commit }, params) {
		await this.$axios.get(`/master/game-providers`, { params })
			.then((data) => {
				commit('setGameProviders', data.data)
				return true;
			})
			.catch((_) => false)
	},
	async rsaPublicKey({ commit }, params) {
		await this.$axios.get(`/master/rsa-public-key`, { params })
			.then((data) => {
				commit('setRsaPublicKeys', data.data)
				return true;
			})
			.catch((_) => false)
	},
	async getOperatorInfo({ commit }, params) {
		await this.$axios.get(`/master/operator-info`, { params })
			.then((data) => {
				commit('setOperatorInfo', data.data)
				return true;
			})
			.catch((_) => false)
	},
	async searchGame({ commit, state }, params) {
		// game_name
		if (!params.game_name) return false
		if (params.game_name.length < 3) {
			commit('setSearchGame', [])
			return false
		}
		if (state.masterCancelToken.searchGame) {
			state.masterCancelToken.searchGame.cancel('')
		}
		commit('setMasterCancelToken', { searchGame: this.$axios.CancelToken.source() })

		commit('setLoading', true)
		await this.$axios.get(`/master/games`, {
			params,
			cancelToken: state.masterCancelToken.searchGame.token
		})
			.then((data) => {
				commit('setSearchGame', data.data)
				return true;
			})
			.catch((_) => false)
		commit('setLoading', false)
	},
	async getOperationSetting({ commit }, currency) {
		await this.$axios.get(`/master/operation-setting/${currency}`, {})
			.then((data) => {
				commit('setOperationSetting', data.data)
				return true;
			})
			.catch((_) => false)
	},
	async getThirdPartyPaymentChannelsCurrency({ commit }, params) {
		await this.$axios.get(`/master/third-party-payment-channels-currency`, { params })
			.then((data) => {
				commit('setThirdPartyPaymentChannelCurrency', data.data)
				return true;
			})
			.catch((_) => false)
	},
	async getThirdPartyPaymentProvidersChannels({ commit }, { currency, gatewayId }) {
		await this.$axios.get(`/master/third-party-providers-channels/${currency}/${gatewayId}`, {})
			.then((data) => {
				commit('setThirdPartyPaymentChannelsTypes', data.data)
				return true;
			})
			.catch((_) => false)
	},
	async getBannerCategories({ commit }, params) {
		await this.$axios.get(`/master/banner-categories`, { params })
			.then((data) => {
				commit('setBannerCategories', data.data)
				return true;
			})
			.catch((_) => false)
	},
	async getCryptoCurrencies({ commit }, params) {
		await this.$axios.get(`/master/crypto-currency`, { params })
			.then((data) => {
				commit('setCryptoCurrencies', data.data)
				return true;
			})
			.catch((_) => false)
	},
	async getCompanyCryptoWalletForManualDeposit({ commit }, params) {
		commit('setCompanyCryptoWalletForManualDepositLoading', true)
		await this.$axios.get(`/master/company-crypto-wallet/manual-deposit`, { params })
			.then((data) => {
				commit('setCompanyCryptoWalletForManualDeposit', data.data)
				return true;
			})
			.catch((_) => false)
		commit('setCompanyCryptoWalletForManualDepositLoading', false)
	},
	async getCompanyCryptoWalletForWithdrawal({ commit }, params) {
		await this.$axios.get(`/master/company-crypto-wallet/withdrawals`, { params })
			.then((data) => {
				commit('setCompanyCryptoWalletForWithdrawal', data.data)
				return true;
			})
			.catch((_) => false)
	},
}

export const mutations = {
	setSuperAdmin(state, data) {
		state.superAdmin = data
	},
	setCurrency(state, data) {
		state.currency = data
	},
	setLanguages(state, data) {
		state.languages = data
	},
	setTranslationStrings: (state, v) => {
		state.translationString = v
	},
	loadingStringsForTranslation: (state, v) => {
		state.loadingStringsForTranslation = v
	},
	setAgents(state, data) {
		const coverData = JSON.parse(JSON.stringify(data))
		const sortData = coverData.sort((a, b) => a.name.localeCompare(b.name))
		state.agents = sortData
	},
	setCompanyBankList(state, data) {
		state.companyBankList = data
	},
	playerBankList(state, data) {
		state.playerBankList = data
	},
	playerBankForDeposit(state, data) {
		state.playerBankForDeposit = data
	},
	setCompanyBankAccounts(state, data) {
		state.companyBankAccounts = data
	},
	setAdmins(state, data) {
		state.admins = data
	},
	setPlayerIds(state, data) {
		state.playerIds = data
	},
	allBanners(state, data) {
		state.allBanners = data
	},
	allBannersPerformance(state, data) {
		state.allBannersPerformance = data
	},
	gameTypes(state, data) {
		state.gameTypes = data
	},
	setThirdPartyPaymentGateways(state, data) {
		state.thirdPartyPaymentGateways = data
	},
	setThirdPartyPaymentGatewaysChannel(state, data) {
		state.thirdPartyPaymentGatewaysChannels = data
	},
	setExchangeRates(state, data) {
		state.exchangeRates = data
	},
	setCompanyCryptoWallets(state, data) {
		state.companyCryptoWallets = data
	},
	setPlayerCryptoWallets(state, data) {
		state.playerCryptoWallets = data
	},
	setDepositFlow(state, data) {
		state.depositFlow = data
	},
	setDomains(state, data) {
		state.domains = data
	},
	paymentTypes(state, data) {
		state.paymentTypes = data
	},
	companyThirdPartyChannels(state, data) {
		state.companyThirdPartyChannels = data
	},
	setLoading(state, data) {
		state.loading = data
	},
	setRsaPublicKeys(state, data) {
		state.rsaPublicKeys = data
	},
	setSearchGame(state, data) {
		state.searchGames = data
	},
	setOperatorInfo(state, data) {
		state.operatorInfo = data
	},
	setGameProviders(state, data) {
		state.gameProviders = data
	},
	setOperationSetting(state, data) {
		state.operationSetting = data
	},
	setThirdPartyPaymentChannelCurrency(state, data) {
		state.thirdPartyPaymentChannelCurrency = data
	},
	setThirdPartyPaymentChannelsTypes(state, data) {
		state.thirdPartyPaymentChannelsTypes = data
	},
	setBannerCategories(state, data) {
		state.bannerCategories = data
	},
	setCryptoCurrencies(state, data) {
		state.cryptoCurrencies = data
	},
	setCompanyCryptoWalletForManualDeposit(state, data) {
		state.companyCryptoWalletForManualDeposit = data
	},
	setCompanyCryptoWalletForManualDepositLoading(state, data) {
		state.companyCryptoWalletForManualDepositLoading = data
	},
	setCompanyCryptoWalletForWithdrawal(state, data) {
		state.companyCryptoWalletForWithdrawal = data
	},
	// cancel token
	setMasterCancelToken: (state, data) => {
		const oldData = { ...state.masterCancelToken }
		state.masterCancelToken = {
			...oldData, ...data
		}
	}
}


export const getters = {
	companyBankAccountSort: (state) => (srt = "desc") => {
		const bankAccounts = JSON.parse(JSON.stringify(state?.companyBankAccounts))
		let sorts = []
		if (srt === 'desc') {
			sorts = bankAccounts?.sort((firstCurrency, secondCurrency) => {
				return secondCurrency.is_active - firstCurrency.is_active
			})
		} else if (srt === 'asc') {
			sorts = bankAccounts?.sort((firstCurrency, secondCurrency) => {
				return firstCurrency.is_active - secondCurrency.is_active
			})
		} else {
			sorts = bankAccounts
		}
		return sorts;
	},
	cryptoCurrencies: (state) => {
		const response = state?.currency?.filter((el) => el.type === 2)
		if (response?.length > 0) {
			return response
		} else {
			return []
		}
	},
	storeTl: state => code => {
		if (state.translationString[code]) return state.translationString[code]
		return code
	}
}